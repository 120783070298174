import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';

// Pages
import Home from 'pages/Home';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as tabsActions from 'redux/actions/navTabs'

class Main extends Component {
	componentDidMount() {
		this.props.actionsMain.get_rules()
	}

	render() {

		return (
			<Router basename={process.env.REACT_APP_BASENAME}>
				<div id="Main" className="main-panel">
					<ErrorBoundary>
						<ViewportRestrict display={false} type="landscape" />
						<Loader loader={false} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={() => <Home {...this.props} />} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</ErrorBoundary>
				</div>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main, tabs: state.tabs }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
		actionsTabs: bindActionCreators(tabsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);