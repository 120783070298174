import React from "react"
import Reveal from "react-reveal/Reveal";

import Rules from "../Rules/Rules"

import "./SectionRules.scss"

class SectionRules extends React.Component {
  tabsClickHandler = (e) => {
    this.props.actionsTabs.toggle_tabs(e.currentTarget.id)
  }
  throwOffsetY = () => {
    let offsetY = document.getElementById("rules").offsetTop
    this.props.onSetOffsetY(offsetY)
  }

  componentDidMount() {
    this.throwOffsetY()
  }
  render() {
    const tabName = this.props.tabs.tabsName
    return (
      <div className="SectionRules" id="rules">
        <img src={require("../../assets/img/Text_Rules.png")} alt="rules" className="rules-img" />
        <div className="nav-tabs">
          <div className={`tabs-item ${tabName === `rules` ? `active` : ``}`} id="rules" onClick={this.tabsClickHandler}>PERATURAN TURNAMEN</div>
          <div className={`tabs-item ${tabName === `prize` ? `active` : ``}`} id="prize" onClick={this.tabsClickHandler}>HADIAH TURNAMEN</div>
        </div>
        <div className="separator"></div>
        <div className="tabs-controller">
          <Reveal>
            <div className={`rules-list ${tabName === `rules` ? `active` : ``}`}>
              <Rules {...this.props} />
            </div>
            <div className={`rules-list ${tabName === `prize` ? `active` : ``}`}>
              <div dangerouslySetInnerHTML={{ __html: this.props.main.rules.text_hadiah }}></div>
            </div>
          </Reveal>
        </div>
      </div>
    )
  }
}

export default SectionRules;