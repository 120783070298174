const initTabsState = {
  tabsName: "rules"
};

const tabs = (state = initTabsState, action) => {
  switch (action.type) {
    case "TOGGLE_TABS":
      return { ...state, tabsName: action.name };
    default:
      return state;
  }
};

export default tabs;
