const main_state = {
	login: false,
	loader: false,
	lang_json: false,
	rules: false,
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data };
		case "SET_RULES":
			return { ...state, rules: action.data };
		default:
			return state;
	}
};

export default main;