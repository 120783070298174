import React from "react"

import "./Footer.scss"

const footer = props => {
  return (
    <div className="Footer">
      <div className="logo-1">
        <img src={require("../../assets/img/LOGO_CODM.png")} alt="codm" id="codm" />
        <a href="https://www.facebook.com/CODMID/"><div className="fb"></div></a>
        <a href="https://www.instagram.com/garenacodmid/ "><div className="ig"></div></a>
        <a href="https://www.youtube.com/channel/UCj3YUPBe-eKUCjBoE-QY2jA"><div className="yt"></div></a>
      </div>
      <div className="logo-2">
        <img src={require("../../assets/img/LOGO_Garena.png")} alt="garena" />
        <img src={require("../../assets/img/Logo_Activision.png")} alt="activision" />
        <img src={require("../../assets/img/Logo_Timi.png")} alt="timi" />
      </div>
      <p>Copyright © Garena Online. Copyright © Tencent. All rights reserved. © 2020 Activision Publishing, Inc. ACTIVISION and CALL OF DUTY are trademarks of Activision Publishing, Inc.Trademarks belong to their respective owners. All rights reserved.</p>
    </div>
  )
}

export default footer;