import React from "react";

import "./Rules.scss"

class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1
    }
  }
  changeTabHandler = (tab) => {
    this.setState({ tab: tab })
  }
  render() {
    const tab = this.state.tab;
    const rules = this.props.main.rules
    return (
      <React.Fragment>
        <div className="tab-control">
          <div className={`tab ${tab === 1 ? 'active' : ''}`} onClick={() => this.changeTabHandler(1)}>KUALIFIKASI TIM</div>
          <div className={`tab ${tab === 2 ? 'active' : ''}`} onClick={() => this.changeTabHandler(2)}>RONDE ELIMINASI</div>
          <div className={`tab ${tab === 3 ? 'active' : ''}`} onClick={() => this.changeTabHandler(3)}>PLAYOFFS</div>
          <div className={`tab ${tab === 4 ? 'active' : ''}`} onClick={() => this.changeTabHandler(4)}>GRAND FINALS</div>
        </div>

        <div className={`tab-content ${tab === 1 ? 'active' : ''}`}>
          <div dangerouslySetInnerHTML={{ __html: rules.text_peraturan_kualifikasi ? rules.text_peraturan_kualifikasi : '' }}></div>
        </div>

        <div className={`tab-content ${tab === 2 ? 'active' : ''}`}>
          <div dangerouslySetInnerHTML={{ __html: rules.text_peraturan_eliminasi }}></div>
        </div>

        <div className={`tab-content ${tab === 3 ? 'active' : ''}`}>
          <div dangerouslySetInnerHTML={{ __html: rules.text_peraturan_playoffs }}></div>
        </div>

        <div className={`tab-content ${tab === 4 ? 'active' : ''}`}>
          <div dangerouslySetInnerHTML={{ __html: rules.text_peraturan_final }}></div>
        </div>

      </React.Fragment>
    )
  }
}

export default Rules;