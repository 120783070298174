import React from "react"
import Fade from "react-reveal/Fade";

import "./SectionGameModes.scss"

class SectionGameModes extends React.Component {
  throwOffsetY = () => {
    let offsetY = document.getElementById("game-modes").offsetTop
    this.props.onSetOffsetY(offsetY)
  }

  componentDidMount() {
    this.throwOffsetY()
  }
  render() {
    return (
      <div className="SectionGameModes" id="game-modes">
        <img src={require("../../assets/img/Text_Competitive.png")} alt="game-modes" className="game-modes-img" />
        <div className="cards">
          <Fade left>
            <div className="card-bm"></div>
            <div className="card-hp"></div>
            <div className="card-domi"></div>
          </Fade>
        </div>
      </div>
    )
  }
}

export default SectionGameModes;