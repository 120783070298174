import React, { Component } from 'react';
import SectionHeader from "../../partial/SectionHeader/SectionHeader";
import SectionInvitation from "../../partial/SectionInvitation/SectionInvitation"
import SectionTimeline from "../../partial/SectionTimeline/SectionTimeline"
import SectionGameModes from "../../partial/SectionGameModes/SectionGameModes"
import SectionRules from "../../partial/SectionRules/SectionRules"
import Footer from "../../partial/Footer/Footer"
import Navbar from "../../partial/Navbar"
import "./Home.scss"

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarOffsetY: 0,
            timelineOffsetY: 0,
            gameModesOffsetY: 0,
            rulesOffsetY: 0
        }
    }
    getNavbarOffsetY = (offsetY) => {
        this.setState({ navbarOffsetY: offsetY })
    }
    getTimelineOffsetY = (offsetY) => {
        this.setState({ timelineOffsetY: offsetY })
    }
    getGameModesOffsetY = (offsetY) => {
        this.setState({ gameModesOffsetY: offsetY })
    }
    getRulesOffsetY = (offsetY) => {
        this.setState({ rulesOffsetY: offsetY })
    }
    render() {
        return (
            <div>
                <SectionHeader navbarOffsetY={this.state.navbarOffsetY} />
                <Navbar onSetOffsetY={this.getNavbarOffsetY} offsets={this.state} />
                <SectionInvitation />
                <SectionTimeline onSetOffsetY={this.getTimelineOffsetY} />
                <SectionGameModes onSetOffsetY={this.getGameModesOffsetY} />
                <SectionRules {...this.props} onSetOffsetY={this.getRulesOffsetY} />
                <Footer />
            </div>
        );
    }
}

export default Home;