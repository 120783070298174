import React, { Component } from 'react';

import "./Navbar.scss"

class Navbar extends Component {
    state = {
        links: [
            {
                route: 'https://esports.codm.garena.com/',
                text: 'esports'
            },
            {
                route: '/',
                text: 'home'
            },
            {
                route: '#timeline',
                text: 'timeline'
            },
            {
                route: '#game-modes',
                text: 'game modes'
            },
            {
                route: '#rules',
                text: 'rules'
            }
        ]
    }

    handleScroll = (id) => {
        if (id === 0) {
            window.location.href = 'https://esports.codm.garena.com/';
        } else if (id === 2) {
            window.scrollTo({ top: this.props.offsets.timelineOffsetY, left: 0, behavior: 'smooth' })
        } else if (id === 3) {
            window.scrollTo({ top: this.props.offsets.gameModesOffsetY, left: 0, behavior: 'smooth' })
        } else if (id === 4) {
            window.scrollTo({ top: this.props.offsets.rulesOffsetY, left: 0, behavior: 'smooth' })
        }
    }

    renderNavbar = () => {
        let list;
        list = this.state.links.map((data, index) =>
            <li key={index}>
                <p onClick={() => this.handleScroll(index)}>{data.text}</p>
            </li>
        )
        return list;
    }

    throwOffsetY = () => {
        let offsetY = document.getElementsByClassName("Navbar")[0].offsetTop
        this.props.onSetOffsetY(offsetY)
    }

    componentDidMount() {
        this.throwOffsetY()
    }

    render() {
        return (
            <ul className="Navbar">
                {this.renderNavbar()}
            </ul>
        );
    }
}

export default Navbar;