import React from "react";
import "./SectionHeader.scss"

const sectionHeader = props => {
  function handleScroll() {
    window.scrollTo({ top: props.navbarOffsetY, left: 0, behavior: 'smooth' })
  }
  return (
    <div className="SectionHeader">
      <div className="header-pict">
        <img src={require("../../assets/img/Logo.png")} alt="logo" className="logo" />
        <img src={require("../../assets/img/Scroll_kebawah.gif")} alt="scroll" className="scroll" onClick={handleScroll} />
      </div>
      <div className="header-info">
        <img src={require("../../assets/img/Text_Headline.png")} alt="text-headline" className="text-headline" />
        <p>daftar sekarang melalui event dalam game</p>
        <p>untuk mengikuti major series season 3</p>
      </div>
    </div>
  )
}

export default sectionHeader;