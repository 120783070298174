import React from "react"
import Fade from "react-reveal/Fade";

import Card from "../../helper/component/card/card"

import TimelineBackground from "../../assets/img/Image_Timeline.png"
import "./SectionTimeline.scss"

class SectionTimeline extends React.Component {
  throwOffsetY = () => {
    let offsetY = document.getElementById("timeline").offsetTop
    this.props.onSetOffsetY(offsetY)
  }

  componentDidMount() {
    this.throwOffsetY()
  }
  render() {
    return (
      <div className="SectionTimeline" id="timeline">
        <img src={require("../../assets/img/Text_Timeline.png")} alt="timeline" className="timeline-img" />
        <Fade right>
          <Card background={TimelineBackground} width="50vw" height="50vw" margin="2vw 0" />
        </Fade>
      </div>
    )
  }
}

export default SectionTimeline