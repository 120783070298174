import React from "react";
import Fade from "react-reveal/Fade"
import Card from "../../helper/component/card/card"

import BTRbackground from "../../assets/img/Team_BTR.png"
import DGbackground from "../../assets/img/Team_DG.png"

import "./SectionInvitation.scss";

const sectionInvitation = props => {
  return (
    <div className="SectionInvitation" id="invitation">
      <img src={require("../../assets/img/Text_Invitational.png")} alt="invitational-team" className="invitational-team" />
      <div className="cards">
        <Fade left>
          <Card background={BTRbackground} width="25vw" height="28vw" teamName="bigetron duty" teamStatus="champion major series season 2" />
        </Fade>
        <Fade right>
          <Card background={DGbackground} width="25vw" height="28vw" teamName="dg esports" teamStatus="runner-up major series season 2" />
        </Fade>
      </div>
    </div>
  )
}

export default sectionInvitation;