import React from "react"

import "./card.scss"

class Card extends React.Component {
  render() {
    let style = {
      backgroundImage: `url(${this.props.background})`,
      backgroundSize: '100% 100%',
      width: `${this.props.width}`,
      height: `${this.props.height}`,
      margin: `${this.props.margin}`,
      borderRadius: '10px',
      cursor: this.props.pointer ? 'pointer' : '',
    }
    return (
      <div style={style} className="card">
        <div className="card-content">
          <p className="team-name">{this.props.teamName}</p>
          <p className="team-status">{this.props.teamStatus}</p>
        </div>
      </div>
    )
  }
}

export default Card;